<script>
import {mapActions, mapState} from "vuex";
import {subscribe} from "klaviyo-subscribe";
import axios from "axios";
/**
 * The only thing required for this to run:
 * input field with the `email`
 */
export default {
  name: "Newsletter",
  props: {
    listId: {
      required: true,
      type: String,
    },
  },
  data: () => ({
    loading: false,
    success: false,
    error: false,
    message: "",
  }),
  methods: {
    submitHandler(e) {
      e.preventDefault();
      const {email} = e.target.elements;
      let formData = new FormData(e.target);

      this.loading = true;
      this.error = false;
      this.success = false;

      const messages = {
        success: "Success!",
        error: "Error!",
      };

      const url = `https://abelobjects.us16.list-manage.com/subscribe/post-json?u=e2158dee7315ae979691bdea8&amp;id=4b75d66f34&amp;f_id=00bb72e0f0&c=?`;
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };

      const axiosDataConig = {
        method: "get",
        withCredentials: false,
        url: url,
        params: {
          EMAIL: "patrick.johnson91+test@gmail.com",
        },
        data: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          "content-type": "application/x-www-form-urlencoded",
        },
      };

      axios(axiosDataConig)
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });

      return false;
      // subscribe(this.listID, email.value).then(resp => {
      //   email.value = "Submitting...";
      //   if (resp.success) {
      //     this.success = true;
      //     setTimeout(() => {
      //       e.target.reset();
      //       this.message = messages.success;
      //     }, 600);
      //   } else {
      //     this.error = true;
      //     this.message = resp.errors[0];
      //     email.value = "";
      //   }
      //   this.loading = false;
      // });
    },
  },
  render() {
    return this.$slots.default({
      submitHandler: this.submitHandler,
      success: this.success,
      message: this.message,
      loading: this.loading,
    });
  },
};
</script>
